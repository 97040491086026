export default {
  navbarLeft: [
    {
      name: 'Learn',
      href: '/qml',
      subLinks: [
        { name: 'Demos', href: '/qml/demonstrations' },
        { name: 'Codebook', href: '/codebook/' },
        { name: 'Challenges', href: '/challenges/' },
        { name: 'Videos', href: '/qml/videos' },
        { name: 'Glossary', href: '/qml/glossary' },
        { name: 'Features', href: '/features' },
        { name: 'Education', href: '/education' },
        { name: 'Performance', href: '/performance' },
        { name: 'FAQ', href: '/faq' },
      ],
    },
    {
      name: 'Documentation',
      href: 'https://docs.pennylane.ai',
      subLinks: [
        {
          name: 'API',
          href: 'https://docs.pennylane.ai/en/stable/code/qml.html',
        },
        { name: 'Devices', href: '/plugins' },
        { name: 'GitHub', href: 'https://github.com/PennyLaneAI/pennylane' },
        {
          name: 'Catalyst',
          href: 'https://docs.pennylane.ai/projects/catalyst',
        },
      ],
    },
    {
      name: 'Get involved',
      href: '/get-involved',
    },
    {
      name: 'Datasets',
      href: '/datasets/',
    },
    {
      name: 'Blog',
      href: '/blog/?page=1',
    },
    {
      name: 'Support',
      href: 'https://discuss.pennylane.ai/',
    },
  ],
  navbarRight: {
    github: {
      href: 'https://github.com/PennyLaneAI/pennylane',
      icon: 'bxl-github',
      name: 'GitHub',
    },
    buttonLink: {
      text: 'Install',
      link: '/install',
    },
  },
  signInCard: {
    description:
      'Welcome to PennyLane! To make the most of your experience, sign in or create a free account with us.',
    buttonText: 'Sign in',
    createAccountText: 'Create a free account',
    bottomLinks: [
      { link: '/terms', text: 'Terms of service' },
      { link: '/privacy', text: 'Privacy policy' },
    ],
  },
  mobileButton: {
    text: 'Install',
    link: '/install',
  },
}
