export default {
  description:
    'PennyLane is an open-source software framework for quantum machine learning, quantum chemistry, and quantum computing, with the ability to run on all hardware. Built with ❤️ by [Xanadu](https://xanadu.ai/).',
  footerTopTagline: {
    text: 'Stay updated with our newsletter',
    href: 'https://xanadu.us17.list-manage.com/subscribe?u=725f07a1d1a4337416c3129fd&id=294b062630',
  },
  // footerBottomTagline: {
  //   text: 'Get involved with PennyLane',
  //   href: '/',
  // },
  footerSocials: [
    {
      icon: 'bxl-twitter',
      href: 'https://twitter.com/PennyLaneAI',
      ariaLabel: 'twitter',
    },
    {
      icon: 'bxl-github',
      href: 'https://github.com/PennyLaneAI/pennylane',
      ariaLabel: 'github',
    },
    {
      icon: 'bxl-discord',
      href: 'https://discord.com/invite/gnySM3nrN3',
      ariaLabel: 'discord',
    },
    {
      icon: 'bxl-linkedin',
      href: 'https://www.linkedin.com/company/pennylaneai/',
      ariaLabel: 'linkedin',
    },
    {
      icon: 'bxl-discourse',
      href: 'https://discuss.pennylane.ai',
      ariaLabel: 'discourse',
    },
    {
      icon: 'bxl-youtube',
      href: 'https://www.youtube.com/@pennylaneai/',
      ariaLabel: 'youtube',
    },
    {
      icon: 'bxl-slack',
      href: 'https://join.slack.com/t/xanadu-quantum/shared_invite/zt-1i8v8v49d-S76QxXm3OKCm9g0bvWvDpg',
      ariaLabel: 'slack',
    },
    {
      icon: 'bx-rss',
      href: '/blog/?page=1',
      ariaLabel: 'rss',
    },
  ],
  footerLinks: [
    {
      title: 'For researchers',
      links: [
        {
          name: 'Features',
          href: '/features',
        },
        // {
        //   name: 'Research',
        //   href: '/research',
        // },
        {
          name: 'Demos',
          href: '/qml/demonstrations',
        },
        {
          name: 'Datasets',
          href: '/datasets/',
        },
        {
          name: 'Performance',
          href: '/performance',
        },
        {
          name: 'Learn',
          href: '/qml',
        },
        {
          name: 'Videos',
          href: '/qml/videos',
        },
        {
          name: 'Documentation',
          href: 'https://docs.pennylane.ai',
        },
        {
          name: 'Education',
          href: '/education',
        },
      ],
    },
    {
      title: 'For learners',
      links: [
        {
          name: 'Learn',
          href: '/qml',
        },
        {
          name: 'Codebook',
          href: '/codebook/',
        },
        {
          name: 'Education',
          href: '/education',
        },
        {
          name: 'Videos',
          href: '/qml/videos',
        },
        {
          name: 'Challenges',
          href: '/challenges',
        },
        {
          name: 'Demos',
          href: '/qml/demonstrations',
        },
        {
          name: 'Glossary',
          href: '/qml/glossary',
        },
      ],
    },
    {
      title: 'For developers',
      links: [
        {
          name: 'Features',
          href: '/features',
        },
        {
          name: 'Documentation',
          href: 'https://docs.pennylane.ai',
        },
        {
          name: 'API',
          href: 'https://docs.pennylane.ai/en/stable/code/qml.html',
        },
        {
          name: 'GitHub',
          href: 'https://github.com/PennyLaneAI/pennylane',
        },
        {
          name: 'Datasets',
          href: '/datasets/',
        },
        {
          name: 'Demos',
          href: '/qml/demonstrations',
        },
        {
          name: 'Performance',
          href: '/performance',
        },
        {
          name: 'Devices',
          href: '/plugins',
        },
        {
          name: 'Compilation',
          href: 'https://docs.pennylane.ai/projects/catalyst',
        },
        // {
        //   name: 'Contributing',
        //   href: '/get-involved',
        // },
      ],
    },
  ],
  footerPolicies: [
    {
      text: 'Privacy Policy',
      href: '/privacy',
    },
    {
      text: 'Terms of Service',
      href: '/terms',
    },
    {
      text: 'Cookie Policy',
      href: '/cookies',
    },
    {
      text: 'Code of Conduct',
      href: '/conduct',
    },
  ],
}
