exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../../../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-auth-setup-profile-tsx": () => import("./../../../src/pages/auth/setup-profile.tsx" /* webpackChunkName: "component---src-pages-auth-setup-profile-tsx" */),
  "component---src-pages-auth-verify-email-tsx": () => import("./../../../src/pages/auth/verify-email.tsx" /* webpackChunkName: "component---src-pages-auth-verify-email-tsx" */),
  "component---src-pages-education-tsx": () => import("./../../../src/pages/education.tsx" /* webpackChunkName: "component---src-pages-education-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-features-tsx": () => import("./../../../src/pages/features.tsx" /* webpackChunkName: "component---src-pages-features-tsx" */),
  "component---src-pages-get-involved-tsx": () => import("./../../../src/pages/get-involved.tsx" /* webpackChunkName: "component---src-pages-get-involved-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-install-tsx": () => import("./../../../src/pages/install.tsx" /* webpackChunkName: "component---src-pages-install-tsx" */),
  "component---src-pages-login-tsx": () => import("./../../../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-performance-index-tsx": () => import("./../../../src/pages/performance/index.tsx" /* webpackChunkName: "component---src-pages-performance-index-tsx" */),
  "component---src-pages-plugins-tsx": () => import("./../../../src/pages/plugins.tsx" /* webpackChunkName: "component---src-pages-plugins-tsx" */),
  "component---src-pages-puzzles-tsx": () => import("./../../../src/pages/puzzles.tsx" /* webpackChunkName: "component---src-pages-puzzles-tsx" */),
  "component---src-pages-qml-demonstrations-tsx": () => import("./../../../src/pages/qml/demonstrations.tsx" /* webpackChunkName: "component---src-pages-qml-demonstrations-tsx" */),
  "component---src-pages-qml-demos-community-tsx": () => import("./../../../src/pages/qml/demos_community.tsx" /* webpackChunkName: "component---src-pages-qml-demos-community-tsx" */),
  "component---src-pages-qml-demos-submission-tsx": () => import("./../../../src/pages/qml/demos_submission.tsx" /* webpackChunkName: "component---src-pages-qml-demos-submission-tsx" */),
  "component---src-pages-qml-glossary-index-tsx": () => import("./../../../src/pages/qml/glossary/index.tsx" /* webpackChunkName: "component---src-pages-qml-glossary-index-tsx" */),
  "component---src-pages-qml-index-tsx": () => import("./../../../src/pages/qml/index.tsx" /* webpackChunkName: "component---src-pages-qml-index-tsx" */),
  "component---src-pages-qml-quantum-chemistry-tsx": () => import("./../../../src/pages/qml/quantum-chemistry.tsx" /* webpackChunkName: "component---src-pages-qml-quantum-chemistry-tsx" */),
  "component---src-pages-qml-quantum-computing-tsx": () => import("./../../../src/pages/qml/quantum-computing.tsx" /* webpackChunkName: "component---src-pages-qml-quantum-computing-tsx" */),
  "component---src-pages-qml-quantum-machine-learning-tsx": () => import("./../../../src/pages/qml/quantum-machine-learning.tsx" /* webpackChunkName: "component---src-pages-qml-quantum-machine-learning-tsx" */),
  "component---src-pages-qml-videos-tsx": () => import("./../../../src/pages/qml/videos.tsx" /* webpackChunkName: "component---src-pages-qml-videos-tsx" */),
  "component---src-pages-qml-what-is-quantum-chemistry-tsx": () => import("./../../../src/pages/qml/what-is-quantum-chemistry.tsx" /* webpackChunkName: "component---src-pages-qml-what-is-quantum-chemistry-tsx" */),
  "component---src-pages-qml-what-is-quantum-computing-tsx": () => import("./../../../src/pages/qml/what-is-quantum-computing.tsx" /* webpackChunkName: "component---src-pages-qml-what-is-quantum-computing-tsx" */),
  "component---src-pages-qml-whatisqml-tsx": () => import("./../../../src/pages/qml/whatisqml.tsx" /* webpackChunkName: "component---src-pages-qml-whatisqml-tsx" */),
  "component---src-pages-redirect-route-tsx": () => import("./../../../src/pages/redirect/[...route].tsx" /* webpackChunkName: "component---src-pages-redirect-route-tsx" */),
  "component---src-pages-search-tsx": () => import("./../../../src/pages/search.tsx" /* webpackChunkName: "component---src-pages-search-tsx" */),
  "component---src-templates-blog-homepage-tsx": () => import("./../../../src/templates/blog/homepage.tsx" /* webpackChunkName: "component---src-templates-blog-homepage-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog/post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-blog-tag-tsx": () => import("./../../../src/templates/blog/tag.tsx" /* webpackChunkName: "component---src-templates-blog-tag-tsx" */),
  "component---src-templates-demos-individual-demo-demo-tsx": () => import("./../../../src/templates/demos/individualDemo/demo.tsx" /* webpackChunkName: "component---src-templates-demos-individual-demo-demo-tsx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-circuit-ansatz-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/circuit-ansatz/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-circuit-ansatz-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-hybrid-computation-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/hybrid-computation/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-hybrid-computation-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-parameter-shift-rule-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/Parameter-shift Rule/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-parameter-shift-rule-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-convolutional-neural-network-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-convolutional-neural-network/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-convolutional-neural-network-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-differentiable-programming-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-differentiable-programming/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-differentiable-programming-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-embedding-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-embedding/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-embedding-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-feature-map-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-feature-map/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-feature-map-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-gradient-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-gradient/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-gradient-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-neural-network-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-neural-network/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-neural-network-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-node-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/quantum-node/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-quantum-node-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-variational-circuit-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/variational-circuit/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-variational-circuit-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-are-bell-states-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-are-bell-states/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-are-bell-states-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-are-qiskit-primitives-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-are-qiskit-primitives/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-are-qiskit-primitives-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-controlled-u-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-a-controlled-u-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-controlled-u-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-hadamard-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-a-hadamard-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-hadamard-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-qiskit-session-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-a-qiskit-session/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-qiskit-session-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-swap-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-a-swap-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-swap-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-toffoli-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-a-toffoli-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-a-toffoli-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-cuda-quantum-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-cuda-quantum/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-cuda-quantum-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-cuquantum-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-cuquantum/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-cuquantum-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-dirac-notation-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-dirac-notation/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-dirac-notation-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-ibm-quantum-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-ibm-quantum/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-ibm-quantum-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qiskit-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-qiskit/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qiskit-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qiskit-runtime-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-qiskit-runtime/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qiskit-runtime-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qjit-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-qjit/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-qjit-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-identity-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-the-identity-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-identity-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-no-cloning-theorem-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-the-no-cloning-theorem/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-no-cloning-theorem-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-quantum-not-gate-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-the-quantum-not-gate/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-the-quantum-not-gate-index-mdx" */),
  "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-von-neumann-entropy-index-mdx": () => import("./../../../src/templates/glossary/glossary.tsx?__contentFilePath=/home/runner/work/pennylane.ai-react/pennylane.ai-react/apps/pennylane-website/content/glossary/what-is-von-neumann-entropy/index.mdx" /* webpackChunkName: "component---src-templates-glossary-glossary-tsx-content-file-path-home-runner-work-pennylane-ai-react-pennylane-ai-react-apps-pennylane-website-content-glossary-what-is-von-neumann-entropy-index-mdx" */),
  "component---src-templates-legal-legal-tsx": () => import("./../../../src/templates/legal/legal.tsx" /* webpackChunkName: "component---src-templates-legal-legal-tsx" */)
}

